import React, { FC } from 'react'
import { makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'

type ClassType = {
	icon: string
}

const classes: ClassType = makeClasses({
	icon: {
		color: Colors.ligthPurple,
		marginLeft: '5px'
	}
})

const RequiredIcon: FC = () => {
	return <span className={classes.icon}>*</span>
}

export default RequiredIcon
