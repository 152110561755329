import React, { FC, useContext } from 'react'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Text from '@components/ui/text'

type Prop = {
	hasError?: boolean
	errorText?: string
}

type Classes = {
	root: string
}

const classes: Classes = makeClasses({
	root: {
		fontSize: '14px',
		lineHeight: '14px',
		fontWeight: 400,
		color: Colors.errorRed,
		padding: '5px 0',
		p: {
			margin: 0
		}
	}
})

const Error: FC<Prop> = ({ hasError, errorText }) => {
	const { pageData } = useContext(AppStateContext)

	const text: string = errorText ? errorText : pageData?.assets?.required_text

	return (
		<>
			{hasError && (
				<div className={joinClasses([classes.root, 'error-text'])}>
					<Text content={text} />
				</div>
			)}
		</>
	)
}

export default Error
