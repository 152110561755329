import React, { FC, useEffect } from 'react'
import { joinClasses, makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Text from '@components/ui/text'
import { Icon } from '../Icon'
import {
	closeGreyIcon,
	closeIcon,
	closeIconGreen,
	externalLink,
	warningIcon,
	warningYellowIcon
} from '@images/icons'
import Breakpoints from '@utils/breakpoints'

interface NotificationProps {
	type: 'info' | 'warning' | 'error' | 'success'
	text: string
	hasHtml?: boolean
	showExitBtn?: boolean
	children?: React.ReactNode
	onClickCancelBtn?: () => void
	externalIcon?: boolean
	textStyle?: string
	showSpinner?: boolean
	showPrefixIcon?: boolean
	addPaddingAroundText?: boolean
	selfRedirect?: boolean
}

type ClassType = {
	closeImg: string | undefined
	info: string
	warning: string
	error: string
	success: string
	notification: string
	externalLik: string
	alignItems: string
	loader: string
	spaceLeft: string
	textWithPadding: string
}

const classes: ClassType = makeClasses({
	notification: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		border: '1px solid',
		padding: '20px',
		marginBottom: '20px',
		width: '100%',
		'& p': {
			display: 'inline'
		},
		'& a': {
			color: Colors.secondary
		},
		[Breakpoints.maxWidth('md')]: {
			fontSize: '14px',
			padding: '15px'
		}
	},
	info: {
		backgroundColor: `${Colors.lightBlue}33`,
		borderColor: Colors.lightBlue
	},
	warning: {
		backgroundColor: `${Colors.lightYellow}33`,
		borderColor: Colors.lightYellow,
		lineHeight: 1.6,
		fontWeight: 400
	},
	error: {
		backgroundColor: `${Colors.errorRed}33`,
		borderColor: Colors.errorRed
	},
	success: {
		backgroundColor: `${Colors.lightGreen}33`,
		borderColor: Colors.lightGreen
	},
	closeImg: {
		display: 'inline-flex',
		width: '100%',
		maxWidth: '25px',
		height: '25px',
		marginLeft: '10px',
		cursor: 'pointer',
		fill: Colors.lightGreen,
		img: {
			width: '100%',
			height: '100%'
		}
	},
	externalLik: {
		display: 'inline-flex',
		alignItems: 'center',
		marginRight: '10px',
		maxWidth: '22px',
		maxHeight: '22px',
		width: '100%',
		height: '100%',
		marginBottom: '-8px'
	},
	alignItems: {
		display: 'flex',
		alignItems: 'center'
	},
	loader: {
		position: 'absolute' as 'absolute',
		border: `4px solid ${Colors.lightGrey}`,
		borderTop: `4px solid ${Colors.blue}`,
		borderRadius: '50%',
		width: '26px',
		height: '26px',
		animation: 'spin 2s linear infinite',
		marginTop: '-3px !important'
	},
	spaceLeft: {
		marginLeft: '35px'
	},
	textWithPadding: {
		backgroundColor: Colors.white,
		color: Colors.red,
		padding: '5px'
	}
})
const closeIcons = {
	success: closeIconGreen,
	error: closeGreyIcon
}

const prefixIcon = {
	info: warningIcon,
	warning: warningYellowIcon
}

const Notification: FC<NotificationProps> = ({
	type,
	text,
	hasHtml,
	children,
	showExitBtn,
	onClickCancelBtn,
	externalIcon,
	textStyle,
	showSpinner,
	showPrefixIcon,
	addPaddingAroundText,
	selfRedirect
}) => {
	const addLinkAttribute = () => {
		document
			.querySelector(`.${classes.notification}`)
			?.querySelectorAll('a')
			.forEach((link) => {
				if (!link.href.includes('#')) {
					let target = selfRedirect ? '_self' : '_blank'
					link.setAttribute('target', target)
				}
			})
	}

	useEffect(() => {
		if (hasHtml) {
			addLinkAttribute()
		}
	}, [])

	// const renderTextWithLineBreaks = (text) => {
	// 	if (text.includes('\n')) {
	// 		const lines = text.split('\n')
	// 		const lastIndex = lines.length - 1

	// 		return lines.map((line, index) => (
	// 			<React.Fragment key={index}>
	// 				{line}
	// 				{index !== lastIndex && (
	// 					<>
	// 						<br />
	// 						<br />
	// 					</>
	// 				)}
	// 			</React.Fragment>
	// 		))
	// 	}

	// 	return text
	// }

	return (
		<div
			className={joinClasses([
				classes.notification,
				classes[type],
				'notification'
			])}
		>
			<span>
				<span className={joinClasses([classes.alignItems, textStyle ?? ''])}>
					{showPrefixIcon && (
						<span className={classes.closeImg} style={{ marginRight: '10px' }}>
							<Icon src={prefixIcon[type]} />
						</span>
					)}
					{showSpinner && <div className={`${classes.loader} spin`} />}
					<span
						className={joinClasses([
							showSpinner ? classes.spaceLeft : '',
							addPaddingAroundText ? classes.textWithPadding : ''
						])}
					>
						{!hasHtml && <>{text}</>}
						{hasHtml && <Text content={text} />}
						{externalIcon && (
							<Icon src={externalLink} className={classes.externalLik} />
						)}
					</span>
				</span>
				{children}
			</span>
			<span>
				{showExitBtn && (
					<span
						className={classes.closeImg}
						onClick={() => onClickCancelBtn!()}
					>
						<Icon src={closeIcons[type]} />
					</span>
				)}
			</span>
		</div>
	)
}

export default Notification
